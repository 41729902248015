/**
 * This AppLayout component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import { React } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { ApplicationLayout, AppErrorBoundary } from 'ia-react-core';
import { useTranslation } from 'react-i18next';

const AppLayoutPropTypes = {
  children: PropTypes.any,
};

const AppLayout = ({ children }) => {
  const { t } = useTranslation('AppLayout');
  
  return (
    <AppErrorBoundary>
      <Helmet />
      { t('language') === 'fr' && (
        <img src="/iA_PW-V-RGB_F.png" alt="iA" className="marginLeft100" height={55}></img>
      )}
      { t('language') !== 'fr' && (
        <img src="/iA_PW-V-RGB_E.png" alt="iA" className="marginLeft100" height={55}></img>
      )}
      <ApplicationLayout 
        noHeaderLogo
        noAppHeader
        noHeaderLanguageButton
        headerHeight={0}
      >            
        {children}
      </ApplicationLayout>
    </AppErrorBoundary>
  );
};

AppLayout.propTypes = AppLayoutPropTypes;

export default AppLayout;
