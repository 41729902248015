import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PageLoader } from 'ia-react-core';

import AppLayout from './layouts/AppLayout';

// Pages
const HomePage = () => <PageLoader loader={() => import('./pages/HomePage')} />;
const NotFoundPage = () => <PageLoader loader={() => import('./pages/NotFoundPage')} />;

const App = () => (
  <AppLayout>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route component={NotFoundPage} />
    </Switch>
  </AppLayout>
);

export default App;
