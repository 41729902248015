import { compose } from 'redux';

import {
  withStore,
  withSaga,
  injectReducer,
  withTheming,
  configTranslation,
  withRouter,
  injectTranslations,
  configGrid,
} from 'ia-react-core';

import appTheme from './themes/appTheme';
import appReducer from './App.reducer';
import App from './App';

import fr from './translations/fr';
import en from './translations/en';

const initialState = {};
const storeOptions = { initialState };

const initialAppState = {};

const ConfiguredApp = compose(
  withStore(storeOptions),
  withSaga,
  withTheming(appTheme),
  configTranslation({
    saveCookie: true,
  }),
  withRouter,
  injectTranslations('App', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
  configGrid,
  injectReducer({ 
    key: 'App',
    reducer: appReducer,
    initialState: initialAppState, 
    useScope: true,
  }),
)(App);

export default ConfiguredApp;
