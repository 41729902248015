import { compose } from 'redux';
import { injectTranslations, injectReducer } from 'ia-react-core';

import appLayoutReducer from './AppLayoutReducer.reducer';
import AppLayout from './AppLayout';

import fr from './translations/fr';
import en from './translations/en';

const initialState = { };

export default compose(
  injectTranslations('AppLayout', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
  injectReducer({ 
    key: 'AppLayout',
    reducer: appLayoutReducer,
    initialState, 
    useScope: true,
    cleanState: true,
  }),
)(AppLayout);
